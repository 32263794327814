import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxDefault from '../../templates/mdx-default.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxDefault;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <section className="main style1">
      <header className="small">
        <h1 {...{
          "id": "john-crawford"
        }}>{`John Crawford`}</h1>
        <h3 {...{
          "id": "emrg-faculty-director"
        }}>{`EMRG Faculty Director`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-img-attributes",
            "style": {
              "display": "block",
              "width": "60%",
              "marginLeft": "auto",
              "marginRight": "auto",
              "paddingTop": "0.75rem"
            }
          }}><span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "550px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABcBAQEBAQAAAAAAAAAAAAAAAAIDAAH/2gAMAwEAAhADEAAAAdN+bYqbjFO9TLQKc//EAB0QAAICAQUAAAAAAAAAAAAAAAECAxEABBITISL/2gAIAQEAAQUCZyixScuPqypeVJBDdte4jpHNE+v/xAAYEQACAwAAAAAAAAAAAAAAAAABEAIhQf/aAAgBAwEBPwGIpYv/xAAZEQACAwEAAAAAAAAAAAAAAAAAAQIQESH/2gAIAQIBAT8Bb6aRr//EAB0QAAMAAQUBAAAAAAAAAAAAAAABESECEjFBYnH/2gAIAQEABj8CUJqhEiJZLt6hnkfyl8jwmf/EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVFxYf/aAAgBAQABPyG3m53B1zKSgO4VQYKOvIMQBkfsdmo2SrzQHJdJzN4i9s//2gAMAwEAAgADAAAAENzwP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIUH/2gAIAQMBAT8QB3BdQZf/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARQf/aAAgBAgEBPxDbRFLOK//EAB0QAQEAAwACAwAAAAAAAAAAAAERACExQVFxgZH/2gAIAQEAAT8QVtKk4nzglZA4f1kqY2CtuFRQgtLH6e8oW+DSwzd66yTHIhsTCZVTY8tw4qBiUoZ6/CKrCZ//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "John Crawford",
                "title": "John Crawford",
                "src": "/static/6312765579e37aa422bed367bb0a7ad1/021b5/jc-profile-550x550.jpg",
                "srcSet": ["/static/6312765579e37aa422bed367bb0a7ad1/021b5/jc-profile-550x550.jpg 550w"],
                "sizes": "(max-width: 550px) 100vw, 550px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></span></p>
      </header>
      <div className="inner">
        <p><strong parentName="p">{`John Crawford`}</strong>{` is an intermedia artist, performance director and systems designer. Engaging directly with the emerging technologies of our time, his work creates rich immersive environments and compelling interactive experiences through embodied interaction.`}</p>
        <p>{`His artistic projects are performed in theatres, exhibited in galleries and presented as interactive installations, featuring creative exploration of environmentalism, climate justice and community engagement. His work investigates how the human desire to transform our environment is unleashing destructive forces that endanger the world and everything in it, including ourselves. Projects currently in development include `}<a parentName="p" {...{
            "href": "http://crisis.artlab.eco"
          }}>{`Cryosphere Crisis`}</a>{`, `}<a parentName="p" {...{
            "href": "http://trace.artlab.eco"
          }}>{`Leave A Trace`}</a>{` and `}<a parentName="p" {...{
            "href": "http://yomo.artlab.eco"
          }}>{`YO~MO`}</a>{`. He originated the "Active Space" concept for his intermedia software framework which he has continued to evolve and develop for almost thirty years, dedicated to connecting body-centered artistic practices with evolving methods for expanded media performance and multi-site interactivity.`}</p>
        <p>{`He is Professor of Intermedia Arts in the Dance Department at University of California, Irvine, where he founded and directs the `}<a parentName="p" {...{
            "href": "http://emrg.embodied.net"
          }}>{`Embodied Media Research Group`}</a>{`, featuring a range of projects, courses and collaboratories that integrate socially engaged artmaking and connected design thinking with emergent media production. At UCI he was Associate Dean in the Claire Trevor School of the Arts and has served in many other leadership roles. He is a strong advocate for UCI's initiatives to expand transdisciplinary arts-based creativity, including the campus-wide Digital Arts Minor program which he directed for nine years.`}</p>
        <p>{`He is the co-founder of Eco ArtLab and Eco DesignLab, two nonprofit organizations employing art and design to respond to the escalating crisis driven by the rapidly evolving human capacity to alter planetary ecosystems. `}<a parentName="p" {...{
            "href": "http://artlab.eco"
          }}>{`Eco ArtLab`}</a>{` creates artistic performances and exhibitions that enhance ecological awareness, engaging with new forms of expression enabled by the emerging media technologies of our time. `}<a parentName="p" {...{
            "href": "http://designlab.eco"
          }}>{`Eco DesignLab`}</a>{` is developing an online platform to support transdisciplinary artistic projects, drawing on insights gained from collaborative explorations in dance, music and theatre. These new initiatives are positioned as creative hubs to support synergistic groups of artists and designers concerned with environmental issues.`}</p>
        <p>{`Active as an intermedia artist since 1992, Crawford has shown his work across North America and in Asia, Europe and South America, and frequently has been a visiting artist and researcher at universities and other venues in the United States, Europe, China, Japan and India. He is a regular participant in transdisciplinary research projects connecting performing arts with computer science, engineering, and medicine.`}</p>
        <p>{`Prior to working as a professor, he led a dual life, alternating between parallel vocations: theatre artist and software developer. As a theatre director and actor in the 1970s, he studied with Sanford Meisner at the Neighborhood Playhouse in New York, worked with theatre companies including Factory Theatre Lab and Theatre Passe Muraille, and directed an experimental performance group in Vancouver, Canada. As a software designer and research manager in the 1980s and 1990s, his credits included projects for Adobe, Microsoft and many other companies.`}</p>
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      